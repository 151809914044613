<script lang="ts" setup></script>
<template>
  <section class="cofidis-leader primary text-primary section-steps bg-gray-5 py-8">
    <div class="container mx-auto">
      <div
        class="w-full relative flex flex-wrap items-center justify-center lg:h-48"
      >
        <nuxt-img
          format="webp"
          class="sm:mr-10 h-40"
          alt="Prestito Personale – Cofidis Certificazione Ottima Finanziaria"
          title="Logo Certificazione Ottima Finanziaria"
          src="/images/static/ottima_finanziaria_2022.png"
        />
        <h2
          class="w-full sm:w-auto font-semibold text-2xl text-white leading-none md:text-3xl lg:text-4xl mt-6 sm:mt-0 text-center"
        >
          Cofidis è il leader del credito a distanza
        </h2>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
