<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";
import YouTubeIframeLoader from "youtube-iframe";
import { computed, reactive, ref, watch } from "vue";

const { videoId } = defineProps({
  videoId: { type: String, required: true },
});

const playerId = computed(() => {
  return `player${uuidv4()}`;
});

const emit = defineEmits(["close"]);

const playerState = ref<number>(-1);

const platformIsIOS = ref<boolean>(false);

const videoPlayerContainer = ref<HTMLDivElement | null>(null);

/**
 * Initialize YT Player
 * https://developers.google.com/youtube/iframe_api_reference
 */
const initializePlayer = () => {
  if (videoPlayerContainer.value) {
    YouTubeIframeLoader.load(function (YT) {
      if (!YT) {
        console.error("YouTube iframe API failed to load.");
        return;
      }

      window.YTplayer = new YT.Player(videoPlayerContainer.value?.id, {
        height: "100%",
        width: "100%",
        videoId: videoId,
        playerVars: {
          wmode: "transparent",
          autoplay: 0,
          rel: 0,
          loop: 0,
          controls: 1,
          showinfo: 0,
          autohide: 1,
          modestbranding: 1,
          vq: "hd1080",
        },
        events: {
          onStateChange: onPlayerStateChange,
        },
      });
    });
  }
};

/**
 * API Reference
 * https://developers.google.com/youtube/iframe_api_reference?hl=es#onStateChange
 * @param state
 */
const onPlayerStateChange = (state: any) => {
  playerState.value = state.data;

  if (state.data === 0) {
    window.YTplayer.stopVideo();
    emit("close");
  } //Stop the video when it ends
};

onMounted(() => {
  const isIPhone = /iPhone/i.test(navigator.userAgent);
  platformIsIOS.value = isIPhone;

  if (!isIPhone) {
    initializePlayer();

    try {
      setTimeout(() => {
        window.YTplayer?.playVideo();
      }, 1000);
    } catch (e) {
      fallbackRedirect(`https://www.youtube.com/watch?v=${videoId}`);
    }
  } else {
    playerState.value = 0;
  }
});

const stop = () => {
  emit("close");
  window.YTplayer?.stopVideo();
};
</script>

<template>
  <div
    class="media fixed top-0 left-0 w-full h-screen z-50 bg-black bg-opacity-25 flex items-center"
    :class="{ hidden: false }"
  >
    <div
      class="w-full h-screen relative flex items-start sm:items-center justify-center bg-black sm:bg-transparent pt-16 sm:pt-0"
      :class="{ hidden: playerState < 0 }"
    >
      <button
        class="absolute top-3 sm:bottom-auto sm:top-14 right-3 sm:right-2/4 sm:transform sm:translate-x-1/2 font-semibold text-sm sm:text-lg text-gray-4 bg-yellow uppercase max-w-xs rounded-full shadow-2xl px-2 sm:px-8 py-2 sm:py-3 sm:max-w-none sm:w-auto"
        @click="stop()"
      >
        <span class="hidden sm:block">chiudi</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-gray-4 sm:hidden"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <iframe
        v-if="platformIsIOS"
        width="100%"
        height="85%"
        :src="`https://www.youtube.com/embed/${videoId}?autohide=1&modestbranding=1&rel=0&showinfo=0`"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >
      </iframe>

      <div
        v-else
        :id="playerId"
        ref="videoPlayerContainer"
        class="w-full h-5/6 sm:w-1/2 sm:h-2/3 bg-black"
      ></div>
    </div>
    <div class="w-full text-center" :class="{ hidden: playerState > -1 }">
      <svg
        class="animate-spin -ml-1 mr-3 h-8 w-8 text-yellow mx-auto inline-block"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25 stroke-yellow"
          cx="12"
          cy="12"
          r="10"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
