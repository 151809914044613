<script setup lang="ts">
import type { Component } from 'vue';
import SlideUpDown from "vue-slide-up-down";

interface Faq {
  q: String;
  a: String;
}

const { items, show, visibleFaqs } = defineProps({
  items: { type: Array as () => Faq[], required: true },
  show: { type: Number, required: true },
  visibleFaqs: { type: Number, required: false, default: 3 }
});

const activeFaq: Ref<Number | null> = ref(null);

const allFaqs: Ref<Boolean> = ref(false);

const faqRefs: Component = useTemplateRef('faq-container')

const toggleAllFaqs = () => {
  allFaqs.value = !allFaqs.value
  if (allFaqs.value) {
    nextTick(() => {
      faqRefs?.value[visibleFaqs+1].querySelector('button.faq-question').focus()
    })
  }
}

const toggleFaq = (i: Number) => {
  if (activeFaq.value == i) activeFaq.value = null;
  else activeFaq.value = i;
};
</script>

<template>
  <section class="section-faq py-10 bg-gray-1 md:py-16 primary">
    <div class="sm:container px-6 mx-auto">
      <h3
        class="font-semibold text-3xl text-gray-5 leading-none px-6 mb-10 text-center lg:text-4xl"
      >
        <slot name="title" />
      </h3>

      <div class="faq-container w-full hover:text-orange cursor-pointer mb-5" :class="{ hidden: i > visibleFaqs && !allFaqs }" v-for="(faq, i) in items" :key="i" ref="faq-container">

        <h4 class="block w-full">
          <button :id="`faq-question-${i}`" type="button" :aria-expanded="activeFaq == i" @click="toggleFaq(i)" class="faq-question flex w-full" :aria-controls="`faq-content-${i}`" >
            <span class="inline-block h-10 w-10 text-center rounded-full mr-5 -mt-2 flex items-center justify-center border" :class="{'border-gray-4': activeFaq != i, 'bg-yellow border-yellow shadow-xl': activeFaq == i}">
              <svg xmlns="http://www.w3.org/2000/svg" class="text-white h-7 w-7 stroke-gray-4" fill="none" viewBox="0 0 24 24" :class="{'transform rotate-180': activeFaq != i }">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </span>
            <span class="w-4/5 inline-block mb-4 font-semibold text-2xl text-gray-5 leading-tight text-left" :class="{ 'active text-red-1': activeFaq == i }">
              {{ faq.q }}
            </span>
          </button>
        </h4>

        <SlideUpDown :id="`faq-content-${i}`" class="ml-10 pl-5 font-medium text-gray-5 cursor-default outline-none" :active="activeFaq == i" :aria-labelledby="`faq-content-${i}`" role="region">
          <span class="font-light text-lg text-gray-5 mb-4 inline-block" v-html="faq.a"></span>
        </SlideUpDown>
        
      </div>

      <div class="flex justify-center">
        <button v-if="allFaqs" @click="toggleAllFaqs" class="font-semibold text-lg text-red-1 text-center cursor-pointer mt-6 uppercase border-b-4 border-red-1">
            Mostra meno
        </button>
        <button v-else @click="toggleAllFaqs" class="inline font-semibold text-lg text-red-1 text-center cursor-pointer mt-6 uppercase border-b-4 border-red-1">
            Carica altre
        </button>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
