<script setup lang="ts">
import DropdownArrow from "~/components/icons/dropdown-arrow/DropdownArrow.vue";
import ErrorMessage from "~/components/ui/ErrorMessage.vue";

import type { IValue } from "~/interfaces";

defineComponent({
  name: "Select",
});

const { name, values } = defineProps({
  name: {
    type: String,
    default: () => null,
    required: false,
  },
  values: {
    type: Array as () => IValue[],
    default: () => [],
    required: true,
  },
  disableZeroValue: {
    type: Boolean,
    required: false,
    default: false,
  },
  floating: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  label: {
    type: String,
    required: false,
    default: "",
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  error: {
    type: Boolean,
    required: false,
    default: false,
  },
  errorMessage: {
    type: String,
    required: false,
    default: "Seleziona un'opzione",
  },
  testId: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["change"]);

const selectElement = useTemplateRef('form-select')
defineExpose({ selectElement })

const value = defineModel<any>();

const uid = getCurrentInstance()?.uid;

</script>

<template>
  <div :class="['select primary', floating && 'label-floating']">
    <div class="form-select">
      <select
        v-model="value"
        :class="[
          $attrs.class,
          floating ? 'floating-select' : 'normal-select',
          value != values[0].value && 'selected',
          error ? 'error' : '',
        ]"
        :aria-label="values[0].label"
        :name="name"
        :disabled="disabled"
        :data-testid="testId"
        @change="emit('change')"
        ref="form-select"
        :id="`select-${uid}`"
       >
        <option
          v-for="(option, i) in values"
          :key="`${i}_${option.value}`"
          :value="option.value"
          :disabled="(disableZeroValue && i == 0) || option.disabled"
        >
          {{ option.label }}
        </option>
      </select>
      <DropdownArrow
        class="absolute top-2.5 right-2 text-gray-900 pointer-events-none"
        size="1.66rem"
      />
    </div>
    <label
      :for="`select-${uid}`"
      v-if="floating && !disabled"
      :class="[value != values[0].value ? 'selectWithValue' : 'z-[-1]']"
      >{{ label }}
    </label>
  </div>
  <ErrorMessage v-if="error" :message="errorMessage" />
</template>

<style scoped lang="scss"></style>
