<script setup lang="ts">
import type { ITextIcon } from "~/interfaces";
import Cta from "~/components/ui/Cta.vue";

const emit = defineEmits(["click"]);

const { cta, icons } = defineProps({
  cta: { type: String, required: false, default: "" },
  icons: { type: Array as () => ITextIcon[], required: true },
});

const iconComponents = computed(() => {
  return icons.map((icon) => iconComponent(icon));
});
</script>

<template>
  <section class="section-icons bg-white pb-12 sm:pb-16 primary">
    <div class="section-title sm:container text-center mx-auto">
      <h2
        v-if="$slots.title"
        class="font-semibold text-gray-5 leading-7 px-6 mt-8 sm:mt-10 mb-4 text-2xl md:text-3xl md:bg-white py-4 bg-yellow"
      >
        <slot name="title"></slot>
      </h2>

      <p
        v-if="$slots.text"
        class="font-light text-gray-4 lg:px-24 2xl:px-64 mt-4 mb-8 text-lg lg:text-2xl px-6"
      >
        <slot name="text"></slot>
      </p>
    </div>
    <div class="section-icons-container container mx-auto">
      <div class="flex flex-wrap md:py-6 xs:mt-4 justify-center xl:px-32">
        <template v-for="(icon, index) in iconComponents" :key="index">
          <div
            v-inview.enter="
              `animate__animated animateFadeInUp animate__fadeInUp animate__${index + 1}`
            "
            class="section-icon w-1/2 md:w-1/5 text-center mb-6"
          >
            <span
              class="flex items-center justify-center rounded-full w-18 h-18 mx-auto"
            >
              <component :is="icon.icon" class=""> </component>
            </span>
            <h3
              class="font-semibold text-xl text-gray-5 leading-tight my-2"
              v-html="icon.text"
            ></h3>
          </div>
        </template>
      </div>
    </div>
    <div class="text-center" v-inview.enter="`animate__animated animateFadeInUp animate__6 animate__fadeInUp`">
      <Cta :text="cta" href="#" @click="$emit('click')" />
    </div>
  </section>
</template>
