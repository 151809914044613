<script setup lang="ts">
import Select from "~/components/inputs/Select.vue";
import Button from "~/components/inputs/Button.vue";
import type { IValue } from "~/interfaces";

const { ctaText, ctaSubtext, value } = defineProps({
  ctaText: { type: String, required: true },
  ctaSubtext: { type: String, required: false, default: "" },
  values: { type: Array as () => IValue[], default: () => [], required: true },
  value: { type: Number, required: false },
});

const credit = useState<number>("selectedCredit");

defineComponent({
  name: "CreditAlt",
});

defineEmits(["submit"]);
</script>

<template>
  <!-- Section amount -->
  <section class="section-amount bg-gray-5 py-10">
    <div class="container mx-auto px-2 sm:px-10 text-center">
      <h2 class="font-semibold text-3xl text-white leading-tight px-8 mb-4 sm:px-0 md:text-4xl">
        <slot name="title"></slot>
      </h2>
      <p class="font-light text-lg text-white mb-5 lg:text-xl">
        <slot name="text"></slot>
      </p>
      <form class="mx-4" @submit.prevent="$emit('submit')">
        <div class="max-w-md mx-auto">
          <Select
            v-model="credit"
            class="w-full py-2 px-6 leading-normal rounded-full h-12 text-lg focus-within:shadow-white"
            disable-zero-value
            :values="values"
            test-id="selectedCreditAlt"
          ></Select>
          <button
            type="submit"
            class="home-submit"
            data-testid="creditAltSubmit"
          >
            <span class="block font-bold text-xl leading-5">{{ ctaText }}</span>
            <span v-if="ctaSubtext" class="block leading-5 text-base">{{
              ctaSubtext
            }}</span>
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<style scoped>
.home-submit {
  @apply w-full sm:px-16 py-2 mt-4 font-semibold text-xl shadow-2xl;
}
@screen sm {
  .home-submit {
    @apply inline-block w-auto py-3;
  }
}
</style>
