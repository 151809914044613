<script setup lang="ts"></script>

<template>
  <h1 class="slanted-container container block mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl pl-5 2xl:px-0 mt-48 sm:mt-2 relative z-20 primary">
    <span class="block md:px-14 lg:px-2 ml-2 line-1">
      <span class="w-fit slanted sm:ml-4 px-4 sm:px-4">
        <slot name="line1"></slot>
      </span>
    </span>
    <span class="block ml-2 sm:ml-10 mt-1 mb-3 md:mb-10 md:px-14 lg:px-2 line-2">
      <span class="w-fit slanted sm:-ml-4 px-4 sm:pr-4">
        <slot name="line2"></slot>
      </span>
    </span>
  </h1>
</template>

<style scoped></style>
