<script setup lang="ts">
import type { IData } from "~/interfaces";
import Credit from "~/components/forms/Credit.vue";
import GoalIcons from "~/components/static/GoalIcons.vue";
import ClubBanner from "~/components/static/ClubBanner.vue";
import StepCards from "~/components/static/StepCards.vue";
import KPIs from "~/components/static/KPIs.vue";
import FAQ from "~/components/static/FAQ.vue";
import CofidisLeader from "~/components/static/CofidisLeader.vue";
import Header from "~/components/static/Header.vue";
import Disclaimer from "~/components/static/Disclaimer.vue";
import Badges from "~/components/static/Badges.vue";
import TrustPilotSwiper from "~/components/static/TrustPilotSwiper.vue";
import CreditAlt from "~/components/forms/CreditAlt.vue";
import Info from "~/components/icons/Info.vue";
import YoutubeModal from "~/components/modals/YoutubeModal.vue";
import type { Component } from "vue";

const data: IData = getThemeData();

const iconsCredit = [
  {
    text: 'Prevalutazione <br class="sm:hidden" />immediata',
    icon: "Flash",
  },
  {
    text: 'Tutto <br class="sm:hidden" />online',
    icon: "Online",
  },
  {
    text: "Rata fissa",
    icon: "RataFissa",
  },
  {
    text: "Zero spese accessorie",
    icon: "ZeroExpense",
  },
];
const iconsGoal = [
  {
    text: "Spese <br /> quotidiane",
    icon: "Money",
  },
  {
    text: "Ristrutturare <br /> casa",
    icon: "House",
  },
  {
    text: "Chiudere <br /> altri prestiti",
    icon: "Handshake",
  },
  {
    text: "Acquistare <br /> un veicolo",
    icon: "Vehicles",
  },
  {
    text: "Realizzare <br /> i tuoi desideri",
    icon: "Dreams",
  },
];
const steps = [
  { title: "Scegli l’importo", text: "Richiedi da 600€ a 30.000€" },
  {
    title: "Invia la richiesta",
    text: "Inserisci i tuoi dati. Bastano solo pochi minuti!",
  },
  {
    title: "Ricevi l’esito",
    text: "Prevalutazione immediata con esito in 24 ore",
  },
  {
    title: "Firma con un sms",
    text: "Firma la richiesta di finanziamento direttamente dal tuo smartphone",
  },
];
const faqs = [
  {
    q: "Cosa serve per la richiesta di credito Cofidis online?",
    a: "Per la richiesta di credito Cofidis online basta inserire i tuoi dati personali nel form, incluso il tuo codice fiscale e l’IBAN del tuo conto corrente bancario.",
  },
  {
    q: "Dove trovo il mio IBAN?",
    a: `Trovi il tuo IBAN nel servizio Home Banking della tua banca (sia web che app) o sull’estratto conto fornito dalla tua banca.`,
  },
  {
    q: "Cosa vuol dire prevalutazione immediata?",
    a: `Per il prestito personale e la linea di credito revolving, ti comunicheremo subito via e-mail l’esito della richiesta online.
        Se l’esito della prevalutazione è positivo, ti invieremo il contratto da firmare digitalmente con un codice inviato via SMS sul tuo smartphone.<br>
        Per la cessione del quinto invece, verrai contattato da un nostro consulente entro 24h, nei giorni lavorativi, per proporti l’offerta più in linea con le tue esigenze.`,
  },
  {
    q: "Quanto posso chiedere?",
    a: "Puoi chiedere l’importo che preferisci: da 600€ fino a 30.000 € in base alle tue esigenze.",
  },
  {
    q: "Cos’è un contratto con firma digitale?",
    a: `<span class="sm:mr-2">È un contratto che ricevi via e-mail e puoi firmare con una OTP (One Time Password) cioè un codice numerico che ricevi via SMS sul tuo smartphone.</span>
        <button id="playVideo" class="inline-block font-medium text-lg text-red-1 text-center cursor-pointer  border-b-2 border-red-1 cursor-pointer">Guarda il video</but>`,
  },
  {
    q: "Quali documenti devo allegare al contratto di Prestito Personale Cofidis e di Linea di Credito Revolving?",
    a: "Basta un documento di identità a scelta fra Carta d’Identità, Patente, Passaporto. Inoltre è necessaria la tessera sanitaria, un documento che attesti il tuo reddito e il frontespizio IBAN fornito dalla tua banca (per verificare che l’IBAN inserito in fase di richiesta sia corretto e poterti effettuare l’accredito sul conto corrente).",
  },
  {
    q: "Quando ricevo la somma di denaro richiesta?",
    a: "Per il prestito personale e la linea di credito revolving, dopo la verifica da parte dei nostri analisti del contratto e della documentazione allegata, avrai l’esito della pratica entro 24 ore. In caso di esito positivo, riceverai la somma di denaro richiesta entro 48 ore sul tuo conto corrente.<br>Per la cessione del quinto invece, verrai contattato da un nostro consulente entro 24h, nei giorni lavorativi, per avere i dettagli sulle tempistiche in funzione del tuo caso.",
  },
  {
    q: "Qual è il metodo di pagamento per pagare le rate?",
    a: "Per il prestito personale e la linea di credito revolving, addebito automatico sul tuo conto corrente (SDD).<br>Per la cessione del quinto invece, le rate mensili verranno trattenute direttamente dallo stipendio o dalla pensione.",
  },
];

const emit = defineEmits(["init", "submit"]);

const acceptedCookie = getStateValue("ytAcceptedCookie");

const showYTModal = ref(false);

const creditForm: Component = useTemplateRef('credit-form');

const videoId = "N6FcjxNSa1U";

onMounted(() => {
  emit("init");

  const button = document.getElementById("playVideo");
  if (button) {
    button.addEventListener("click", () => {
      if (acceptedCookie) {
        showYTModal.value = true;
      } else {
        fallbackRedirect(
          `https://www.youtube.com/watch?v=${videoId}&ab_channel=CofidisItalia`,
        );
      }
    });
  }
});

const focusCreditSelect = () => {
  creditForm.value.creditSelect.selectElement.focus()
};

</script>

<template>
  <div class="homepage -mt-[1px]">
    <YoutubeModal
      v-if="showYTModal"
      video-id="N6FcjxNSa1U"
      @close="showYTModal = false"
    />

    <Header
      :line1="data.header.lines[0]"
      :line2="data.header.lines[1] ?? ''"
      :bg-desktop="data.header.cover.desktop"
      :bg-mobile="data.header.cover.mobile"
    >
      <Credit
        class="relative z-30"
        :values="getCreditValues()"
        :icons="iconsCredit"
        ref="credit-form"
        cta-text="Continua"
        cta-subtext="Esito immediato"
        @submit="emit('submit')"
      >
        <template #title
          >Il credito<br class="sm:hidden" />
          per i tuoi progetti</template
        >
        <template #text
          >Ti bastano <strong class="font-bold">3 minuti</strong>, ovunque tu
          sia</template
        >
      </Credit>
    </Header>

    <Disclaimer>
      <template #text>
        <p class="text text-sm md:text-base mb-0 px-4 pt-6 md:pt-0">
          Un prestito ti vincola e deve essere rimborsato. Controlla le tue
          capacità di rimborso prima di impegnarti anche per evitare
          segnalazioni in banche dati.
        </p>
        <p
          class="w-full text-center text-gray-4 text-xs md:text-sm px-8 pt-4 md:pt-6 font-light md:italic"
        >
          <span v-html="data.promo?.disclaimer"></span>
          <Info
            class="inline-block ml-1 -mt-1 cursor-pointer w-3 h-3 mb-0.5"
            @click="updateStateValue('promoModal', true)"
          />
        </p>
      </template>
    </Disclaimer>

    <GoalIcons
      :icons="iconsGoal"
      cta="Scegli un importo"
      class="relative"
      @click="focusCreditSelect"
    >
      <template #title
        >Il credito che vuoi, <br class="sm:hidden" />
        per fare quello che vuoi</template
      >
      <template #text>
        Che siano di vita o di tutti i giorni, con noi i tuoi progetti hanno
        fiducia immediata. Infatti puoi utilizzare il credito per fare quello
        che vuoi
      </template>
    </GoalIcons>

    <ClubBanner cta="Scegli un importo" @click="focusCreditSelect" />

    <StepCards :steps="steps" cta="Scegli un importo" @click="focusCreditSelect">
      <template #title
        >Il credito su misura per <br class="sm:hidden" />
        te, in 4 semplici passi</template
      >
    </StepCards>

    <KPIs />

    <CofidisLeader />

    <TrustPilotSwiper>
      <template #title
        >Cosa dicono i <br class="sm:hidden" />nostri clienti</template
      >
    </TrustPilotSwiper>

    <FAQ :items="faqs" :show="4">
      <template #title
        >Trova la risposta <br class="sm:hidden" />alle tue domande</template
      >
    </FAQ>

    <CreditAlt
      :values="getCreditValues()"
      cta-text="Continua"
      cta-subtext="Esito immediato"
      @submit="emit('submit')"
    >
      <template #title
        >Stai pensando <br class="sm:hidden" />a un importo?</template
      >
      <template #text>Ok adesso sceglilo qui sotto</template>
    </CreditAlt>

    <Badges />
  </div>
</template>
