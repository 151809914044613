<script setup lang="ts"></script>

<template>
  <div class="logo primary flex justify-end container mx-auto px-6  max-w-4xl lg:max-w-5xl xl:max-w-7xl z-20">
    <div class="z-40 bg-white rounded-b-2xl py-2 px-4 md:px-4 lg:-mr-6 2xl:-mr-24 shadow-sm">
      <img class="w-20 sm:hidden" src="/images/static/logo.svg" alt="Cofidis Logo" title="Cofidis Logo" />
      <img class="w-40 hidden sm:block" src="/images/static/logo-claim.svg" alt="Cofidis Logo" title="Cofidis Logo" />
    </div>
  </div>
</template>
